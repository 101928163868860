import React, {HTMLAttributes} from 'react';
import cx from 'classnames';

import './index.scss';

const Body1: React.FC<HTMLAttributes<HTMLHeadingElement>> = ({ className, ...otherProps }) => {
    return (
        <div className={cx("brand-body1 font-sans", className)} {...otherProps} />
    )
};

export default Body1;