import React, {useMemo} from 'react';
import TextLink from "../button/textLink";
import cx from "classnames";
import useTrackEvent from "../../../hooks/useTrackEvent";

interface Props {
    groups: {
        [key: string]: any
    }
}

type Anchor = {
    label: string;
    enabled: boolean
}

const AlphabetAnchors: React.FC<Props> = ({ groups }) => {
    const trackEvent = useTrackEvent();

    const groupAnchors: Anchor[] = useMemo(() => {
        const anchors: Anchor[] = [];
        for(let index = 0; index < 26; index++) {
            const label = String.fromCharCode(97 + index);
            anchors.push({
                label,
                enabled: Boolean(groups[label])
            })
        }
        return anchors;
    }, [groups]);

    const onLetterClick = (letter: string) => {
        trackEvent("letter_anchor_click", { letter });
    }

    return (
        <div className="flex flex-wrap w-full justify-center">
            {groupAnchors.map((anchor: Anchor) => (
                <TextLink key={anchor.label} onClick={() => onLetterClick(anchor.label)} href={`#${anchor.label}`} target="_self" className={cx("h-11 mr-4 mb-3")} disabled={!anchor.enabled}>
                    {anchor.label}
                </TextLink>
            ))}
        </div>
    )
};

export default AlphabetAnchors;
