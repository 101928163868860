import React from 'react';
import cx from "classnames";

interface Props {
    label: string;
    checked: boolean;
    startIcon?: any;
    onChange: () => void;
}

const CheckBox: React.FC<Props> = ({checked, onChange, startIcon, label}) => {
    const className = cx("cursor-pointer flex items-center gap-3 p-3 min-w-[300px] rounded-lg group-hover:border-blue", {
        "border-blue border-2 bg-light-blue/10": checked,
        "border-gray-5 border": !checked
    });
    return (
        <div onClick={onChange} className={className}>
            <input className="w-5 h-5 accent-blue pointer-events-none" type="checkbox" checked={!!checked} onChange={onChange}/>
            {startIcon}
            {label}
        </div>
    )
}

export default CheckBox;
