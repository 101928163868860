import React from 'react';
import {useSelector} from "react-redux";
import { useDispatch } from "react-redux";

import H1 from "../../_common/text/h1";
import Body1 from "../../_common/text/body1";
import {
    currentlyPrescribedSelector,
    groupedMedicationsKeysSelector,
    groupedMedicationsSelector
} from "../../../redux/data/selectors";
import {toggleCurrentlyPrescribed} from "../../../redux/data";
import AlphabetAnchors from "../../_common/alphabetAnchors";
import CheckBox from "../../_common/checkbox";
import useTrackEvent from "../../../hooks/useTrackEvent";

interface Props {
}

const Prescribed: React.FC<Props> = () => {
    const medicationGroups = useSelector(groupedMedicationsSelector);
    const groupKeys = useSelector(groupedMedicationsKeysSelector);
    const currentlyPrescribed = useSelector(currentlyPrescribedSelector);
    const dispatch = useDispatch();
    const trackEvent = useTrackEvent();

    const toggleMedication = (medication: string) => {
        trackEvent("prescribed_click", { type: medication, checked: !currentlyPrescribed[medication] });
        dispatch(toggleCurrentlyPrescribed(medication));
    }

    return (
        <div>
            <H1 className="text-dark-blue">
                I am currently prescribed...
            </H1>
            <Body1 className="text-dark-blue my-4 opacity-[65%]">
                Select all that apply. If none press Next to continue.
            </Body1>
            <div className="hidden sm:block my-6">
                <AlphabetAnchors groups={medicationGroups} />
            </div>
            {groupKeys?.map((key, index) => (
                <div key={key} className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3 sm:mb-10 text-left mb-6" id={key}>
                    <div className="sm:hidden pl-3">
                        {key.toUpperCase()}
                    </div>
                    {medicationGroups[key].map((medication: any, index: number) => (
                        // TODO: Getting an error when just using medication.name, so there must be duplicates
                        <div key={`${medication.key}_${index}`}>
                            <CheckBox
                                label={medication.name}
                                checked={currentlyPrescribed[medication.name]}
                                onChange={() => toggleMedication(medication.name)} />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
};

export default Prescribed;
