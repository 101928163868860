import { createTheme } from "@mui/material";

// TODO: Tweak this for IAF
export default createTheme({
    palette: {
        primary: {
            main: '#1c5c7f',
            // dark: '#c20000',
            contrastText: "#fafafa"
        },
        secondary: {
            main: '#53c9e8',
            contrastText: '#ffffff',
        },
    },
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    fontFamily: "Roboto",
                    textTransform: "none",
                    paddingLeft: "2px",
                    paddingRight: "2px",
                    textSize: "14px",
                    minWidth: "auto",
                },
                // textColorSecondary: {
                //     color: "#1c5c7f",
                //     minWidth: 60,
                //     paddingLeft: 15,
                //     paddingRight: 15,
                // }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    display: "none"
                }
            }
        },
        // MuiTabs: {
        //     styleOverrides: {
        //         scrollButtons: {
        //             color: "#ffffff"
        //         },
        //
        //     }
        // },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "Oswald"
                }
            }
        }
    }
});
