import {useCallback} from "react";
import {useDispatch} from "react-redux";

import {trackEvent} from "../redux/data";

function useTrackEvent() {
    const dispatch = useDispatch();

    return useCallback((eventName: string, payload?: any) => {
        // @ts-ignore
        dispatch(trackEvent(eventName, payload));
    }, [dispatch]);
}

export default useTrackEvent;
