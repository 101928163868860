import { initializeApp } from "firebase/app";
import { getDatabase, ref, get } from "firebase/database";
import { getAnalytics, logEvent } from "firebase/analytics";

const app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});

const analytics = getAnalytics(app);

const api = {
    fetchMedications: async () => {
        return api.fetchCollection("meds");
    },
    fetchDiseases: async () => {
        return api.fetchCollection("diseases");
    },
    fetchResults: async () => {
        return api.fetchCollection("results");
    },
    // fetchGeneralData: async () => {
    //     const data = await Promise.all([
    //         api.fetchCollection("generalFund"),
    //         api.fetchCollection("generalSupport"),
    //         api.fetchCollection("generalTransportation"),
    //     ]);
    //     return {
    //         fund: data[0],
    //         supportGroups: data[1],
    //         transportation: data[2],
    //     }
    // },
    // fetchFoundations: async () => {
    //     return api.fetchCollection("foundations");
    // },
    // fetchSupportGroups: async () => {
    //     return api.fetchCollection("supportGroups");
    // },
    // fetchMedFunds: async () => {
    //     return api.fetchCollection("medFunds");
    // },
    // fetchMedsToMedFunds: async () => {
    //     return api.fetchCollection("medsToMedFunds");
    // },
    // fetchDiseaseTransportationData: async () => {
    //     const data = await Promise.all([
    //         api.fetchCollection("diseaseTransportation"),
    //         api.fetchCollection("diseasesToDiseaseTransportation"),
    //     ]);
    //     return {
    //         diseaseTransportation: data[0],
    //         diseasesToDiseaseTransportation: data[1],
    //     }
    // },
    // fetchDiseaseFoundations: async () => {
    //     return api.fetchCollection("diseaseFoundation");
    // },
    // fetchResults: async (lookingFor: SelectionMap, diagnosedWith: SelectionMap, currentlyPrescribed: SelectionMap) => {
    //     const results = {
    //         payments: [],
    //         transportation: [],
    //         support: [],
    //     }
    //
    //     // 1. Fetch General Data
    //     const general = await api.fetchGeneralData();
    //
    //     // if lookingFor includes [Payments] {
    //     // - foundations
    //     //   if diseases selected {
    //     //      - diseaseFoundation
    //     //   }
    //     //   if medications selected {
    //     //      - medsToMedFunds
    //     //      - medFunds
    //     //   }
    //     //}
    //     if (lookingFor[LOOKING_FOR.PAYMENTS.value]) {
    //         const foundations = await api.fetchFoundations();
    //         if (Object.keys(diagnosedWith).length) {
    //             const diseaseFoundations = await api.fetchDiseaseFoundations();
    //         }
    //         if (Object.keys(currentlyPrescribed).length) {
    //             const medsToMedFunds = await api.fetchMedsToMedFunds();
    //             const
    //         }
    //     }
    //
    //
    //
    //     //
    //     // if lookingFor includes [Transportation] {
    //     //   if diseases selected {
    //     //      - diseaseTransportation
    //     //      - diseaseToDiseaseTransportation
    //     //   }
    //     //
    //     // if lookingFor includes Support {
    //     // - supportGroups
    //     //   if diseases selected {
    //     //      - diseaseSupportGroups
    //     //   }
    // },
    fetchCollection: async (collectionName: string) => {
        const snapshot = await get(ref(getDatabase(app), collectionName));
        return snapshot.val();
    },
    trackEvent: (eventName: string, payload: any) => {
        logEvent(analytics, eventName, payload);
    }
};

export default api;
