import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from "@reduxjs/toolkit";
import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";

import Home from "./components/home";
import api from "./api";
import rootReducer from "./redux";
import theme from "./theme";

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: { api }
            },
            serializableCheck: false,
        }),
});

function App() {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                    <div className="h-full">
                        <Home />
                    </div>
                </SnackbarProvider>
            </ThemeProvider>
        </Provider>
    );
}

export default App;
