import React, {ButtonHTMLAttributes, MouseEvent, MouseEventHandler} from 'react';
import { Link } from "react-router-dom";
import cx from 'classnames';

import useTrackEvent from "../../../hooks/useTrackEvent";

import './index.scss';

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    to?: string,
    href?: string,
    target?: string,
    eventName?: string,
    eventParams?: any,
}

const ButtonBase: React.FC<Props> = ({ className, to, href, target = "_blank", disabled, children, onClick, eventName, eventParams, ...otherProps }) => {
    const classes = cx("button-base text-sans rounded-[6px] flex items-center", className);
    const trackEvent = useTrackEvent();

    const internalOnClick = (event: MouseEvent<HTMLButtonElement>) => {
        if (eventName) {
            trackEvent(eventName, eventParams);
        }
        if (onClick) {
            onClick(event);
        }
    }

    if (to) {
        return (
            <Link className={classes} to={to} onClick={internalOnClick as MouseEventHandler}>
                {children}
            </Link>
        )
    } else if (href) {
        return (
            <a className={classes} href={href} onClick={internalOnClick as MouseEventHandler} target={target} rel="noopener noreferrer" aria-disabled={disabled}>
                {children}
            </a>
        )
    }
    return (
        <button className={classes} {...otherProps} disabled={disabled} onClick={internalOnClick}>
            {children}
        </button>
    )
};

export default ButtonBase;
