import React, {HTMLAttributes} from 'react';
import cx from 'classnames';

import './index.scss';

const H5: React.FC<HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...otherProps }) => {
    return (
        <h5 className={cx("brand-h5 font-sans font-bold", className)} {...otherProps}>
            {children}
        </h5>
    )
};

export default H5;