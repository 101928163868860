import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {CircularProgress, Tooltip} from "@mui/material";
import {VerifiedUser} from "@mui/icons-material";
import cx from "classnames";

import H1 from "../../_common/text/h1";
import Body1 from "../../_common/text/body1";
import LOOKING_FOR from "../../../data/lookingFor";
import {resultsLoadingSelector, resultsSelector} from "../../../redux/data/selectors";
import useTrackEvent from "../../../hooks/useTrackEvent";

interface Props {
}

const Results: React.FC<Props> = () => {
    const [selectedTab, setSelectedTab] = useState<string|null>(null);
    const resultsLoading = useSelector(resultsLoadingSelector);
    const results = useSelector(resultsSelector);
    const trackEvent = useTrackEvent();

    useEffect(() => {
        if (results && !selectedTab) {
            let tab = null;
            if (results.payments.length) {
                tab = LOOKING_FOR.PAYMENTS.value;
            } else if (results.transportation.length) {
                tab = LOOKING_FOR.TRANSPORTATION.value;
            } else if (results.support.length) {
                tab = LOOKING_FOR.SUPPORT_GROUPS.value;
            }
            setSelectedTab(tab);
            trackEvent("results_displayed", { value: tab });
        }
    }, [results, selectedTab, trackEvent]);

    const visibleTabs = useMemo(() => {
        const ret = [];
        if (results?.payments.length) {
            ret.push(LOOKING_FOR.PAYMENTS);
        }
        if (results?.transportation.length) {
            ret.push(LOOKING_FOR.TRANSPORTATION);
        }
        if (results?.support.length) {
            ret.push(LOOKING_FOR.SUPPORT_GROUPS);
        }
        if (results?.locator) {
            ret.push(LOOKING_FOR.INFUSION_CENTERS);
        }

        return ret;
    }, [results]);

    const visibleResults = () => {
        if (selectedTab) {
            switch (selectedTab) {
                case LOOKING_FOR.PAYMENTS.value:
                    return results.payments;
                case LOOKING_FOR.TRANSPORTATION.value:
                    return results.transportation;
                case LOOKING_FOR.SUPPORT_GROUPS.value:
                    return results.support;
            }
        }
        return null;
    }

    const onResultClick = (name: string) => {
        trackEvent("result_click", { section: selectedTab, name })
    }

    const onTabSelected = (tabValue: string) => {
        if (tabValue !== LOOKING_FOR.INFUSION_CENTERS.value) {
            setSelectedTab(tabValue);
        } else {
            window.open(process.env.REACT_APP_LOCATOR_URL, "_blank");
        }
        trackEvent("result_tab_click", { tab: tabValue });
    }

    return (
        <div>
            <H1 className="text-dark=blue">
                Resources curated just for you!
            </H1>
            <div>
                {resultsLoading ?
                    <div className="flex justify-center">
                        <CircularProgress size={45} />
                    </div> :
                    <div>
                        <div className="flex flex-col sm:flex-row sm:flex-wrap justify-center space-y-2 sm:space-y-0 sm:space-x-3 sm:gap-y-3 my-6">
                            {visibleTabs.length && visibleTabs.map(tab => (
                                <div key={tab.value} className="flex justify-center">
                                    <div className={cx("flex rounded-full border border-dark-blue py-1 px-3 cursor-pointer", {
                                        "bg-dark-blue text-white": selectedTab === tab.value
                                    })} onClick={() => onTabSelected(tab.value)}>
                                        <div className="mr-2">
                                            {tab.icon}
                                        </div>
                                        <div>
                                            {tab.label}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-center">
                            <div className="text-left sm:px-8 sm:max-w-[1000px] w-full">
                                {visibleResults()?.map((result: { name: string; website: string; insuranceRequired: string; }, index: number) => (
                                    <div key={`${selectedTab}_${result.name}_${result.website}_${index}`} className="mb-4">
                                        <a href={result.website} target="_blank" rel="noopener noreferrer" onClick={() => onResultClick(result.name)}>
                                            <Body1 className="text-blue">
                                                {result.name}
                                                {result.insuranceRequired?.toLowerCase() === "yes" &&
                                                    <Tooltip title="Insurance Required">
                                                        <VerifiedUser className="ml-1" />
                                                    </Tooltip>
                                                }
                                            </Body1>
                                            <div className="text-sm text-gray-7">
                                                {result.website}
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
};

export default Results;
