
type Props = {
    active: boolean;
    completed: boolean;
    icon: React.ReactNode;
    error: boolean
}

export const CustomStepIcon: React.FC<Props> = (props) => {
    const PURPLE = '#804499';
    const strokeWidth = 2; // Assuming a stroke width of 2, adjust as needed

    let fill, stroke;
    if (props.active) {
        stroke = PURPLE; 
        fill = 'transparent';
    } else if (props.completed) {
        fill = PURPLE;
        stroke = PURPLE;
    } else {
        fill = "#cdcdd1";
        stroke = "#cdcdd1"; 
    }

    // Adjust the size of the SVG and the circle's position
    const svgSize = 24 + strokeWidth; // Add stroke width to SVG size
    const radius = 12;
    const circlePosition = radius + strokeWidth / 2; // Center the circle considering the stroke width

    return (
        <svg width={svgSize} height={svgSize} viewBox={`0 0 ${svgSize} ${svgSize}`}>
            <circle cx={circlePosition} cy={circlePosition} r={radius} fill={fill} stroke={stroke} strokeWidth={strokeWidth} />
            <text fill={props.active ? PURPLE : '#fff'} fontSize='12' x={circlePosition} y={circlePosition + 4} textAnchor='middle'>
                {props.icon}
            </text>
        </svg>
    ); 
}