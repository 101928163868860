import {
    MonetizationOn,
    Diversity3,
    DirectionsCar,
    LocationOn,
} from '@mui/icons-material/';

const LOOKING_FOR = {
    PAYMENTS: {
        label: "Payments",
        icon: (<MonetizationOn />),
        value: "PAYMENTS",
    },
    TRANSPORTATION: {
        label: "Transportation",
        icon: (<DirectionsCar />),
        value: "TRANSPORTATION",
    },
    SUPPORT_GROUPS: {
        label: "Support Groups",
        icon: (<Diversity3 />),
        value: "SUPPORT_GROUPS",
    },
    INFUSION_CENTERS: {
        label: "Infusion Centers",
        icon: (<LocationOn />),
        value: "INFUSION_CENTERS",
    }
}

export default LOOKING_FOR;
