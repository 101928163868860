import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {toggleDiagnosedWith} from "../../../redux/data";
import CheckBox from "../../_common/checkbox";
import {
    diagnosedWithSelector,
    groupedDiseasesKeysSelector,
    groupedDiseasesSelector,
} from "../../../redux/data/selectors";
import H1 from "../../_common/text/h1";
import Body1 from "../../_common/text/body1";
import AlphabetAnchors from "../../_common/alphabetAnchors";
import useTrackEvent from "../../../hooks/useTrackEvent";

interface Props {}

const DiagnosedWith: React.FC<Props> = () => {
    const diseaseGroups = useSelector(groupedDiseasesSelector);
    const groupKeys = useSelector(groupedDiseasesKeysSelector);
    const selectedDiseases = useSelector(diagnosedWithSelector);
    const dispatch = useDispatch();
    const trackEvent = useTrackEvent();

    const toggleDiseases = (disease: string) => {
        trackEvent("diagnosed_with_click", { type: disease, checked: !selectedDiseases[disease] });
        dispatch(toggleDiagnosedWith(disease));
    };

    return (
        <div>
            <H1 className="text-dark-blue">
                I have been diagnosed with...
            </H1>
            <Body1 className="text-dark-blue my-4 opacity-[65%]">
                Select all that apply. If none press Next to continue.
            </Body1>
            <div className="hidden sm:block my-6">
                <AlphabetAnchors groups={diseaseGroups} />
            </div>
            {groupKeys &&
                groupKeys.map((key, index) => (
                    <div key={key} className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3 sm:mb-10 text-left mb-6" id={key}>
                        <div className="sm:hidden pl-3">
                            {key.toUpperCase()}
                        </div>
                        {diseaseGroups[key].map((disease: any, index: number) => (
                            <div key={disease.name}>
                                <CheckBox
                                    label={disease.name}
                                    checked={selectedDiseases[disease.name]}
                                    onChange={() => toggleDiseases(disease.name)}
                                />
                            </div>
                        ))}
                    </div>
                ))}
        </div>
    );
};

export default DiagnosedWith;
