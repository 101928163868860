import { combineReducers } from 'redux'

import data from './data';

/**
 * Main Redux reducer
 */
const RootReducer = combineReducers({
    data,
});

export default RootReducer;
