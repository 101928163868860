import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import CheckBox from '../../_common/checkbox';
import H1 from "../../_common/text/h1";
import LOOKING_FOR from "../../../data/lookingFor";
import {
    lookingForSelector
} from "../../../redux/data/selectors";
import {toggleLookingFor} from "../../../redux/data";
import Body1 from "../../_common/text/body1";
import useTrackEvent from "../../../hooks/useTrackEvent";

interface Props {
}

const LookingFor: React.FC<Props> = () => {
    const lookingFor = useSelector(lookingForSelector);
    const dispatch = useDispatch();
    const trackEvent = useTrackEvent();

    const onLookingForClick = (value: string) => {
        trackEvent("looking_for_click", { type: value, checked: !lookingFor[value] });
        dispatch(toggleLookingFor(value));
    }

    return (
        <div className="flex flex-col h-full items-center justify-center">
            <H1 className="text-dark-blue">
                I am looking for...
            </H1>
            <Body1 className="text-dark-blue my-4 opacity-[65%]">
                Select all that apply.
            </Body1>
            <div className="space-y-2 flex flex-col w-[310px]">
                {Object.values(LOOKING_FOR).map(lookingForItem => (
                    <CheckBox
                        key={lookingForItem.value}
                        checked={lookingFor[lookingForItem.value]}
                        onChange={() => onLookingForClick(lookingForItem.value)}
                        label={lookingForItem.label}
                        startIcon={lookingForItem.icon}
                    />
                ))}
            </div>
        </div>
    )
};

export default LookingFor;
