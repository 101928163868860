import React from 'react';
import cx from 'classnames';

import ButtonBase, { Props } from "../index";

import './index.scss';

const ButtonSecondary: React.FC<Props> = ({ className, ...otherProps }) => {
    return (
        <ButtonBase className={cx("btn-secondary font-bold justify-center items-center", className)} {...otherProps} />
    )
};

export default ButtonSecondary;
