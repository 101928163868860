import React, {HTMLAttributes} from 'react';
import cx from 'classnames';

import './index.scss';

const H1: React.FC<HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...otherProps }) => {
    return (
        <h1 className={cx("brand-h1 font-serif font-bold", className)} {...otherProps}>
            {children}
        </h1>
    )
};

export default H1;