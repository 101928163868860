import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import H5 from "../_common/text/h5";
import H1 from "../_common/text/h1";
import Body1 from "../_common/text/body1";
import ButtonDefault from "../_common/button/default";
import H3 from "../_common/text/h3";
import ResourceFinder from "../finder";
import PrivacyPolicy from "../privacy";
import { fetchMedications, fetchDiseases, fetchResults } from "../../redux/data";

import logo from '../../img/IAF Logo.svg';
import balance from '../../img/balance.svg';
import leftCircle from '../../img/left_quarter_circle.svg'
import bottomPentagon from '../../img/bottom_pentagon.svg';
import rightEclipse from '../../img/right_eclipse.svg';

const Home: React.FC = () => {
    const [tosOpen, setTosOpen] = useState(false);
    const [getStarted, setGetStarted] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    // Fetch medications and diseases right away
    useEffect(() => {
        // @ts-ignore
        dispatch(fetchMedications(enqueueSnackbar));

        // @ts-ignore
        dispatch(fetchDiseases(enqueueSnackbar));

        // @ts-ignore
        dispatch(fetchResults(enqueueSnackbar));
    }, [dispatch, enqueueSnackbar]);

    return (
        <div className="h-full w-full overflow-hidden flex flex-col">
            <div className="flex flex-1">
                <div className="flex-1 z-10 relative">
                    <div className={cx("h-[150%] invisible sm:visible -top-[25%] right-0 rounded-full bg-white absolute aspect-square sm:transition-all sm:duration-700 sm:ease-in sm:delay-700", {
                        "sm:-translate-x-full": getStarted,
                    })} />
                    <div className="pt-4 pl-4 sm:pt-8 sm:pl-8 relative">
                        <img src={logo} className="h-9 w-auto" alt="Infusion Access Foundation" />
                    </div>
                    <div className="absolute inset-y-0 mx-4 sm:inset-0 flex justify-center items-center">
                        <div className={cx("text-left xs:max-w-[206px] sm:max-w-[334px] md:max-w-[400px] sm:transition-all sm:duration-700 sm:ease-in", {
                            "opacity-0 sm:delay-0": getStarted,
                            "sm:delay-700": !getStarted,
                        })}>
                            <H5 className="uppercase text-purple">Resources Curated For You</H5>
                            <H1 className="text-dark-blue my-4">A Path to Balance</H1>
                            <Body1 className="text-dark-blue">
                                Are you prescribed an infusion or injectable and confused where to start? Copay cards,
                                payment assistance, and patient resources are only a few clicks away.
                            </Body1>
                            <div className="hidden sm:block mt-6">
                                <ButtonDefault onClick={() => setGetStarted(true)} eventName="get_started_click">
                                    Get Started
                                </ButtonDefault>
                            </div>
                            <div className="font-sans mt-6 text-dark-blue hidden sm:block">
                                By continuing you agree to
                                our <span className="underline text-blue cursor-pointer" onClick={() => setTosOpen(true)}>Terms of Service</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:flex-1 flex justify-center items-center relative">
                    <div className="sm:invisible visible absolute transform top-1/4 left-1/2 -translate-y-1/4 -translate-x-1/2 rounded-full aspect-square bg-white w-[220%]" />
                    <img src={balance} className={cx("xs:w-[166px] sm:w-[210px] md:w-[274px] h-auto z-10 transition-all duration-700", {
                        "opacity-0 delay-1200": getStarted,
                        "opacity-100 delay-700": !getStarted,
                    })} alt="Balance" />
                </div>
                <div className={cx("absolute z-20 left-2 right-2 bottom-2 top-2 sm:left-14 sm:right-14 sm:bottom-14 md:left-20 md:right-20 sm:top-0 md:bottom-20 flex flex-col sm:transition-all sm:duration-500", {
                    "opacity-0 pointer-events-none sm:delay-0": !getStarted,
                    "opacity-100 sm:delay-1400": getStarted,
                })}>
                    <div className="w-full sm:my-6 text-center">
                        <h6 className="hidden sm:block uppercase font-sans text-purple font-bold text-[13px] tracking-wider mb-1">
                            Resources curated for you
                        </h6>
                        <H3 className="hidden sm:block text-dark-blue">A Path to Balance</H3>
                    </div>
                    <div className="flex-1 relative">
                        <img src={balance} className={cx("hidden sm:block w-[166px] h-auto absolute left-8 sm:transition-all sm:duration-500 sm:delay-2000", {
                            "-translate-y-20": getStarted
                        })} alt="Balance" />
                        <img src={leftCircle} className="hidden sm:block absolute left-0 top-1/4 -translate-x-20 sm:transition-all sm:duration-500 sm:delay-2000" alt="left-circle" />
                        <img src={bottomPentagon} className="hidden sm:block absolute left-1/2 bottom-0 translate-y-[5.5rem] transform -translate-x-1/2 sm:transition-all sm:duration-500 sm:delay-2000" alt="left-circle" />
                        <img src={rightEclipse} className="hidden sm:block absolute right-0 top-0 translate-x-20 -translate-y-[6.3rem] sm:transition-all sm:duration-500 sm:delay-2000" alt="left-circle" />
                        <div className="w-full h-full relative rounded-xl sm:rounded-sm bg-white shadow-lg sm:shadow-md">
                            <ResourceFinder onExit={() => setGetStarted(false)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="sm:hidden block w-full p-5">
                <div>
                    <ButtonDefault className="w-full" onClick={() => setGetStarted(true)} eventName="get_started_click">
                        Get Started
                    </ButtonDefault>
                </div>
                <div className="text-xs font-sans mt-4 text-center text-dark-blue sm:hidden">
                    By continuing you agree to
                    our <span className="underline text-blue cursor-pointer" onClick={() => setTosOpen(true)}>Terms of Service</span>
                </div>
            </div>
            <PrivacyPolicy
                open={tosOpen}
                onClose={() => setTosOpen(false)} />
        </div>
    )
}

export default Home;
