import React, {HTMLAttributes} from 'react';
import cx from 'classnames';

import './index.scss';

const H3: React.FC<HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...otherProps }) => {
    return (
        <h3 className={cx("brand-h3 font-serif font-bold", className)} {...otherProps}>
            {children}
        </h3>
    )
};

export default H3;