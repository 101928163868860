import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
    medicationsLoading: false,
    diseasesLoading: false,
    medications: null,
    diseases: null,
    lookingFor: {},
    diagnosedWith: {},
    currentlyPrescribed: {},
    insurance: null,
    resultsLoading: false,
    results: null,
};

const dataSlice = createSlice({
    name: 'data',
    initialState: INITIAL_STATE,
    reducers: {
        medicationsLoading(state, action) {
            state.medicationsLoading = action.payload;
        },
        medicationsSuccess(state, action) {
            state.medicationsLoading = false;
            state.medications = action.payload;
        },
        diseasesLoading(state, action) {
            state.diseasesLoading = action.payload;
        },
        diseasesSuccess(state, action) {
            state.diseasesLoading = false;
            state.diseases = action.payload;
        },
        setInsurance(state, action) {
            state.insurance = action.payload;
        },
        toggleLookingFor(state, action) {
            if (state.lookingFor[action.payload]) {
                delete state.lookingFor[action.payload];
            } else {
                state.lookingFor[action.payload] = true;
            }
        },
        toggleDiagnosedWith(state, action) {
            if (state.diagnosedWith[action.payload]) {
                delete state.diagnosedWith[action.payload];
            } else {
                state.diagnosedWith[action.payload] = true;
            }
        },
        toggleCurrentlyPrescribed(state, action) {
            if (state.currentlyPrescribed[action.payload]) {
                delete state.currentlyPrescribed[action.payload];
            } else {
                state.currentlyPrescribed[action.payload] = true;
            }
        },
        resultsLoading(state, action) {
            state.resultsLoading = action.payload;
        },
        resultsSuccess(state, action) {
            state.resultsLoading = false;
            state.results = action.payload;
        }
    }
});

export const {
    medicationsLoading, diseasesLoading, medicationsSuccess, diseasesSuccess, setInsurance, toggleLookingFor,
    toggleCurrentlyPrescribed, toggleDiagnosedWith, resultsLoading, resultsSuccess,
} = dataSlice.actions;

export default dataSlice.reducer

// CUSTOM THUNK ACTIONS

export const fetchMedications = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(medicationsLoading(true));
        return api.fetchMedications()
            .then(medications => {
                dispatch(medicationsSuccess(medications));
            })
            .catch(error => {
                dispatch(medicationsLoading(false));
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const fetchDiseases = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(diseasesLoading(true));
        return api.fetchDiseases()
            .then(diseases => {
                dispatch(diseasesSuccess(diseases));
            })
            .catch(error => {
                dispatch(diseasesLoading(false));
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const fetchResults = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(resultsLoading(true));
        return api.fetchResults()
            .then(results => {
                dispatch(resultsSuccess(results));
            })
            .catch(error => {
                dispatch(resultsLoading(false));
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const trackEvent = (eventName, payload) => (
    (dispatch, getState, { api }) => {
        return api.trackEvent(eventName, payload);
    }
)
