import { Step, StepLabel, Stepper } from "@mui/material"
import { CustomStepConnector } from "./customConnector"
import { CustomStepIcon } from "./customStep";

type Props = {
    activeStep: number;
    steps: { label: string }[];
}
const CustomStepper: React.FC<Props> = ({ activeStep, steps }) => {
    return (
        <Stepper connector={<CustomStepConnector />} activeStep={activeStep} alternativeLabel>
            {steps.map((step) => (
                <Step key={step.label}>
                    <StepLabel
                        StepIconComponent={CustomStepIcon}
                    >{step.label}</StepLabel>
                </Step>
            ))}
        </Stepper>
    )
}

export default CustomStepper;
