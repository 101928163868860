import React from 'react';
import cx from 'classnames';

import ButtonBase, { Props } from "../index";

import './index.scss';

const ButtonDefault: React.FC<Props> = ({ className, ...otherProps }) => {
    return (
        <ButtonBase className={cx("btn-default font-bold flex justify-center items-center transition-all", className)} {...otherProps} />
    )
};

export default ButtonDefault;