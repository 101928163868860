import { createSelector } from "@reduxjs/toolkit";
import LOOKING_FOR from "../../data/lookingFor";

const medicationsSelector = ({ data }) => data.medications;
const diseasesSelector = ({ data }) => data.diseases;
const resultsDataSelector = ({ data }) => data.results;
export const medicationsLoadingSelector = ({ data }) => data.medicationsLoading;
export const diseasesLoadingSelector = ({ data }) => data.diseasesLoading;
export const resultsLoadingSelector = ({ data }) => data.resultsLoading;
export const lookingForSelector = ({ data }) => data.lookingFor;
export const currentlyPrescribedSelector = ({ data }) => data.currentlyPrescribed;
export const diagnosedWithSelector = ({ data }) => data.diagnosedWith;
export const insuranceSelector = ({ data }) => data.insurance;

export const sortedMedicationsSelector = createSelector(
    medicationsSelector,
    (medications) => {
        return [...(medications || [])].sort((m1, m2) => {
            return m1.name.localeCompare(m2.name);
        })
    }
);

export const sortedDiseasesSelector = createSelector(
    diseasesSelector,
    (diseases) => {
        return [...(diseases || [])]?.sort((d1, d2) => {
            return d1.name.localeCompare(d2.name);
        })
    }
);

export const groupedDiseasesSelector = createSelector(
    sortedDiseasesSelector,
    (diseases) => {
        if (diseases) {
            return diseases.reduce((ret, disease) => {
                const key = disease.name.substring(0, 1).toLowerCase();
                if (ret[key]) {
                    ret[key].push(disease);
                } else {
                    ret[key] = [disease];
                }
                return ret;
            }, {});
        }
        return null;
    },
);

export const groupedMedicationsSelector = createSelector(
    sortedMedicationsSelector,
    (medications) => {
        if (medications) {
            return medications.reduce((ret, medication) => {
                const key = medication.name.substring(0, 1).toLowerCase();
                if (ret[key]) {
                    ret[key].push(medication);
                } else {
                    ret[key] = [medication];
                }
                return ret;
            }, {});
        }
        return null;
    },
);

export const groupedMedicationsKeysSelector = createSelector(
    groupedMedicationsSelector,
    (medicationGroups) => {
        if (medicationGroups) {
            return Object.keys(medicationGroups);
        }
        return null;
    },
);

export const groupedDiseasesKeysSelector = createSelector(
    groupedDiseasesSelector,
    (diseaseGroups) => {
        if (diseaseGroups) {
            return Object.keys(diseaseGroups);
        }
        return null;
    },
);

export const resultsDataMapSelector = createSelector(
    resultsDataSelector,
    (resultsData) => {
        const foundationsMap = resultsData.foundations.reduce((ret, foundation) => {
            ret[foundation.name] = foundation;
            return ret;
        }, {});

        const medFundsMap = resultsData.medFunds.reduce((ret, fund) => {
            ret[fund.name] = fund;
            return ret;
        }, {});

        const diseaseTransportationMap = resultsData.diseaseTransportation.reduce((ret, transportation) => {
            ret[transportation.name] = transportation;
            return ret;
        }, {});

        const supportGroupsMap = resultsData.supportGroups.reduce((ret, supportGroup) => {
            ret[supportGroup.name] = supportGroup;
            return ret;
        }, {});

        return {
            foundationsMap,
            medFundsMap,
            diseaseTransportationMap,
            supportGroupsMap,
        }
    }
)

export const resultsSelector = createSelector(
    [lookingForSelector, diagnosedWithSelector, currentlyPrescribedSelector, insuranceSelector, resultsDataSelector, resultsDataMapSelector],
    (lookingFor, diagnosedWith, currentlyPrescribed, insurance, resultsData, resultsDataMap) => {
        let urlTrackingMap = {};
        let paymentResources = [];
        let transportationResources = [];
        let supportResources = [];

        const diseases = Object.keys(diagnosedWith);
        const medications = Object.keys(currentlyPrescribed);
        const hasLookingFor = Object.keys(lookingFor)
            .filter(lookingFor => lookingFor !== LOOKING_FOR.INFUSION_CENTERS.value)
            .length > 0;

        if (!hasLookingFor || lookingFor[LOOKING_FOR.PAYMENTS.value]) {
            if (diseases.length) {
                diseases.forEach(disease => {
                    const diseaseFoundations = resultsData.diseaseFoundations
                        ?.filter(row => row.disease === disease)
                        ?.map(row => resultsDataMap.foundationsMap[row.foundation])
                        ?.filter(row => {
                            if (!urlTrackingMap[row.website]) {
                                urlTrackingMap[row.website] = true;
                                return true;
                            }
                            return false;
                        });

                    paymentResources = [...paymentResources, ...diseaseFoundations];
                })
            }
            if (medications.length) {
                medications.forEach(medication => {
                    const medFunds = resultsData.medsToMedFunds
                        ?.filter(row => row.med === medication)
                        ?.map(row => resultsDataMap.medFundsMap[row.fund])
                        ?.filter(row => insurance === false ? row.insuranceRequired.toLowerCase() !== "yes" : true)
                        ?.filter(row => {
                            if (!urlTrackingMap[row.website]) {
                                urlTrackingMap[row.website] = true;
                                return true;
                            }
                            return false;
                        });

                    // medFunds.sort((f1, f2) => {
                    //     if (f1.insuranceRequired === f2.insuranceRequired) {
                    //         return 0;
                    //     } else if (f1.insuranceRequired.toLowerCase() === "yes") {
                    //         return -1;
                    //     }
                    //     return 1;
                    // });

                    paymentResources = [...paymentResources, ...medFunds];
                })
            }
            paymentResources = [...paymentResources, ...resultsData.generalFund];
        }

        if (!hasLookingFor || lookingFor[LOOKING_FOR.TRANSPORTATION.value]) {
            if (diseases.length) {
                // TODO: Add all the items from diseaseTransportation
                diseases.forEach(disease => {
                    const diseaseTransportations = resultsData.diseasesToDiseaseTransportation
                        .filter(row => row.disease === disease)
                        .map(row => resultsDataMap.diseaseTransportationMap[row.diseaseTransportation])
                        .filter(row => {
                            if (!urlTrackingMap[row.website]) {
                                urlTrackingMap[row.website] = true;
                                return true;
                            }
                            return false;
                        });

                    transportationResources = [...transportationResources, ...diseaseTransportations];
                });
            }

            transportationResources = [...transportationResources, ...resultsData.generalTransportation];
        }

        if (!hasLookingFor || lookingFor[LOOKING_FOR.SUPPORT_GROUPS.value]) {
            if (diseases.length) {
                diseases.forEach(disease => {
                    const diseaseSupportGroups = resultsData.diseaseSupportGroups
                        .filter(row => row.disease === disease)
                        .map(row => resultsDataMap.supportGroupsMap[row.supportGroup])
                        .filter(row => {
                            if (!urlTrackingMap[row.website]) {
                                urlTrackingMap[row.website] = true;
                                return true;
                            }
                            return false;
                        });

                    supportResources = [...supportResources, ...diseaseSupportGroups, ...resultsData.generalSupport];
                })
            }

            supportResources = [...supportResources, ...resultsData.generalSupport];
        }

        return {
            payments: paymentResources,
            transportation: transportationResources,
            support: supportResources,
            locator: lookingFor[LOOKING_FOR.INFUSION_CENTERS.value],
        }
    },
)
