import React from 'react';
import { useDispatch } from "react-redux";
import {
    VerifiedUser,
    GppBad
} from "@mui/icons-material";

import H1 from "../../_common/text/h1";
import Body1 from "../../_common/text/body1";
import {useSelector} from "react-redux";
import {insuranceSelector} from "../../../redux/data/selectors";
import {setInsurance} from "../../../redux/data";
import CheckBox from "../../_common/checkbox";
import useTrackEvent from "../../../hooks/useTrackEvent";

interface Props {
}

const Insurance: React.FC<Props> = () => {
    const insurance = useSelector(insuranceSelector);
    const dispatch = useDispatch();
    const trackEvent = useTrackEvent();

    const toggleInsurance = (insurance: Boolean) => {
        trackEvent("insurance_click", { value: insurance })
        dispatch(setInsurance(insurance));
    }

    return (
        <div className="flex flex-col h-full items-center justify-center">
            <H1 className="text-dark-blue">
                Do you have insurance?
            </H1>
            <Body1 className="text-dark-blue my-4 opacity-[65%]">
                Select Yes or No.
            </Body1>
            <div className="flex justify-center w-full">
                <div className="w-full sm:max-w-[600px]">
                    <div className="mb-3">
                        <CheckBox
                            label="Yes"
                            startIcon={<VerifiedUser />}
                            checked={insurance === true}
                            onChange={() => toggleInsurance(true)} />
                    </div>
                    <div>
                        <CheckBox
                            label="No"
                            startIcon={<GppBad />}
                            checked={insurance === false}
                            onChange={() => toggleInsurance(false)} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Insurance;
