import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Slide, useMediaQuery, useTheme} from "@mui/material";
import { TransitionProps } from '@mui/material/transitions';

import H3 from "../_common/text/h3";
import Body2 from "../_common/text/body2";
import H4 from "../_common/text/h4";
import H5 from "../_common/text/h5";
import ButtonDefault from "../_common/button/default";

interface Props {
    open: boolean;
    onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PrivacyPolicy: React.FC<Props> = ({ open, onClose }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog open={open} maxWidth="md" TransitionComponent={Transition} fullWidth fullScreen={fullScreen} onClose={onClose}>
            <DialogTitle className="text-dark-blue">
                {/* Can't have H2 inside H2, so style this manually */}
                <div className="text-dark-blue text-3xl font-serif font-bold">
                    Privacy Policy &amp; Content Usage Agreement
                </div>
            </DialogTitle>
            <DialogContent className="text-dark-blue">
                <Body2 className="italic mb-4">Effective Date: January 1, 2024</Body2>
                <>
                    <H3 className="mb-2">I. Content Usage and Limitations</H3>
                    <H4 className="mb-2">Content Useage</H4>
                    <H5 className="mb-1">Scope</H5>
                    <Body2 className="mb-3">
                        This Content Use Policy applies to organizations and persons that share or use the application
                        or information provided in the Infusion Access Wizard, henceforth the &quot;Wizard.&quot;
                    </Body2>
                    <H5 className="mb-1">Medical and Legal Disclaimer</H5>
                    <Body2 className="mb-3 break-all">
                        The content provided in the Wizard is intended for educational purposes only. It is not intended
                        to act as medical or legal advice. It does not diagnose or cure any disease. It is not a contract
                        or a promise of any nature. Although our team attempts to provide accurate and timely information,
                        we cannot guarantee the accuracy of the content and do not intend to imply that these resources
                        are a complete or exhaustive list. The resources provided in the Wizard are intended to be a
                        sample of potential resources based on the input the user submits that matches criteria inputted
                        into the Wizard by our research team. To request a feedback form please email <a href="mailto:info@patientaccess.org">info@patientaccess.org</a>.
                    </Body2>
                    <H5 className="mb-1">
                        Use of Content
                    </H5>
                    <Body2 className="mb-3">
                        The creation, design, and development of the Infusion Access Wizard application, research, graphics, design, and code is solely the property of Infusion Access Foundation.  Sharing this Wizard application resource as a link in online, marketing, and print platforms is allowed only if shared as the property of Infusion Access Foundation.
                    </Body2>
                    <H5 className="mb-1">
                        Prohibited Use of Content
                    </H5>
                    <Body2 className="mb-3">
                        Presenting the application “The Wizard” as an entity, person, or organization with ownership of the application is strictly prohibited.
                    </Body2>
                    <H5 className="mb-1">
                        Sell or Distribution
                    </H5>
                    <Body2 className="mb-6">
                        The sell or distribution of Wizard content by entities other than Infusion Access Foundation is strictly prohibited.
                    </Body2>
                </>
                <>
                    <H3 className="mb-2">II. Privacy Policy</H3>
                    <H4 className="mb-2">Scope</H4>
                    <Body2 className="mb-3">
                        This Privacy Policy applies to personal information processed by Infusion Access Foundation (IAF) while utilizing the Wizard Application. All capitalized terms not defined in this Privacy Policy will have the meanings set forth in the IAF Terms of Use.
                    </Body2>
                    <H5 className="mb-1">
                        Personal Information We Collect
                    </H5>
                    <Body2 className="mb-3">
                        When you use our Services, we may collect the following categories of personal information:
                        <ul className="list-disc ml-8">
                            <li>
                                Information You Provide to Us
                            </li>
                            <li>
                                Account Information. When you create an account, we will collect your name and email address. We may also collect your credit or debit card information, address, and phone number. Please note that we use a third-party service provider to collect and process payment card information for donations and payments. IAF does not directly collect or store any payment card information provided to the Services, but it may receive information associated with your payment card information (e.g., your billing details).
                            </li>
                            <li>
                                Communications with Us. We may collect personal information from you such as email address, phone number, or mailing address when you request information about our Services, sign up for our newsletter, or request communication with us
                            </li>
                        </ul>
                    </Body2>
                    <H5 className="mb-1">
                        Information We Collect Through Your Use of the Services
                    </H5>
                    <Body2 className="mb-1">
                        We may collect certain information automatically when you use the Services. This information may include your Internet protocol (IP) address, user settings, MAC address, cookie identifiers, mobile carrier, mobile advertising and other unique identifiers, details about your browser, operating system or device, location information (including inferred location based off of your IP address), Internet service provider, pages that you visit before, during and after using the Services, information about the links you click, information about how you interact with the Services, including the frequency and duration of your activities, and other information about how you use the Services. Information we collect may be associated with accounts and other devices.
                    </Body2>
                    <Body2 className="mb-3 break-all">
                        <ul className="list-disc ml-8">
                            <li>
                                Cookies, Pixel Tags/Web Beacons, and Analytics Information. We, as well as third parties that provide content, advertising, or other functionality on the Services, may use cookies, pixel tags, local storage, and other technologies (“Technologies”) to automatically collect information through the Services. Technologies are essentially small data files placed on your device that allow us and our partners to record certain pieces of information whenever you visit or interact with our Services.
                            </li>
                            <li>
                                Cookies. Cookies are small text files placed in device browsers to store their preferences. Most browsers allow you to block and delete cookies. However, if you do that, the Services may not work properly.
                            </li>
                            <li>
                                Pixel Tags/Web Beacons. A pixel tag (also known as a web beacon) is a piece of code embedded in the Services that collects information about engagement on the Services. The use of a pixel allows us to record, for example, that a user has visited a particular web page or clicked on a particular advertisement. We may also include web beacons in e-mails to understand whether messages have been opened, acted on, or forwarded.
                            </li>
                            <li>
                                Analytics. We may use Google Analytics and other service providers to collect and process analytics information on our Services. For more information about Google Analytics, please visit <a href="https://www.google.com/policies/privacy/partners/" target="_blank" rel="noopener noreferrer">https://www.google.com/policies/privacy/partners/</a>. You can opt out of Google’s collection and processing of data generated by your use of the Site by going to <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout</a>.
                            </li>
                        </ul>
                    </Body2>
                    <H5 className="mb-1">
                        Information from Other Sources
                    </H5>
                    <Body2 className="mb-3">
                        Third Party Services and Organizations. We may obtain information about you from other sources, including through third-party services and other organizations. For example, if you access or use our Services through a third-party application, such as a third-party login service, we may collect information about you from that third party that you have made available via your privacy settings.
                    </Body2>
                    <H5 className="mb-1">
                        How We Use Your Information
                    </H5>
                    <Body2 className="mb-3">
                        <ul className="list-disc ml-8">
                            <li>We process personal information for a variety of business purposes, including:</li>
                            <li>To provide the Services or Information Requested;</li>
                            <li>Manage your information;</li>
                            <li>rovide access to certain areas, functionalities, and features of our Services;</li>
                            <li>Answer requests for support and feedback;</li>
                            <li>Administrative Purposes;</li>
                            <li>Pursue legitimate interests, such as direct marketing, research and development (including marketing research), network and information security, and fraud prevention;</li>
                            <li>Measure interest and engagement in the Services;</li>
                            <li>Develop of new products and services;</li>
                            <li>Improve our products and services;</li>
                            <li>Ensure internal quality control and safety;</li>
                            <li>Verify individual identity;</li>
                            <li>Carry out audits;</li>
                            <li>Prevent and prosecute potentially prohibited or illegal activities;</li>
                            <li>Enforce our agreements; and</li>
                            <li>Comply with our legal obligations.</li>
                        </ul>
                    </Body2>
                    <H5 className="mb-1">
                        Marketing Our Products and Services.
                    </H5>
                    <Body2 className="mb-1">
                        We may use personal information to tailor and provide you with content and advertisements. We may provide you with these materials as permitted by applicable law. If you have any questions about our marketing practices or if you would like to opt out of the use of your personal information for marketing purposes, you may contact us at any time as set forth below.
                    </Body2>
                    <Body2 className="mb-3">
                        <ul className="list-disc ml-8">
                            <li>De-identified and Aggregated Information Use. We may use personal information and other information about you to create de-identified and/or aggregated information, such as de-identified demographic information, de-identified location information, de-identified or aggregated trends, reports, or statistics, or other analyses we create. De-identified and aggregated information is not personal information, and we may use and disclose such information in a number of ways, including research, internal analysis, analytics, and any other legally permissible purposes.</li>
                            <li>De-identified and aggregated information may be shared with Infusion Access Foundation partners for the purpose of identifying market trends, consumer data, analytics, research, and other legally permissible ways. These partners can be fiduciary partners or partners with shared market and mission interests.</li>
                            <li>Consent. IAF may use personal information for other purposes that are clearly disclosed to you at the time you provide personal information or with your consent.</li>
                            <li>Personal information is never shared with third party entities unless required by applicable law to disclose.</li>
                        </ul>
                    </Body2>
                    <H5 className="mb-1">Your Choices</H5>
                    <Body2 className="mb-3">
                        <ul className="list-disc ml-8">
                            <li>General. You may have the right to object to or opt out of certain uses of your personal information.</li>
                            <li>Email Communications. If you receive an unwanted email from us, you can use the unsubscribe link found at the bottom of the email.</li>
                            <li>Technologies and Personalized Advertising. If you would like to opt-out of the Technologies we employ on the Services, you may do so by blocking, disabling, or deleting them as your browser or device permits.</li>
                        </ul>
                    </Body2>
                    <H5 className="mb-1">Individual Rights in Personal Information</H5>
                    <Body2 className="mb-3">IAF does not "sell" your personal information. In accordance with applicable law, you may have the right to: (i) request confirmation of whether we are processing your personal information; (ii) obtain access to or a copy of your personal information; (iii) receive an electronic copy of personal information that you have provided to us, or ask us to send that information to another company (the "right of data portability"); (iv) restrict our uses of your personal information; (v) seek correction or amendment of inaccurate, untrue, incomplete, or improperly processed personal information; (vi) withdraw your consent; and (vii) request erasure of personal information held about you by IAF, subject to certain exceptions prescribed by law. If you would like to exercise any of these rights, please contact us as set forth below. We will process such requests in accordance with applicable laws. To protect your privacy, IAF will take steps to verify your identity before fulfilling your request.</Body2>
                    <H5 className="mb-1">Data Retention</H5>
                    <Body2 className="mb-3">We retain the personal information we receive as described in this Privacy Policy for as long as you use our Services or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements, and comply with applicable laws.</Body2>
                    <H5 className="mb-1">Security of Your Information</H5>
                    <Body2 className="mb-1">We take steps to ensure that your information is treated securely and in accordance with this Privacy Policy. Unfortunately, the Internet cannot be guaranteed to be 100% secure, and we cannot ensure or warrant the security of any information you provide to us. To the fullest extent permitted by applicable law, we do not accept liability for unauthorized disclosure.</Body2>
                    <Body2 className="mb-3">By using the Services or providing personal information to us, you agree that we may communicate with you electronically regarding security, privacy, and administrative issues relating to your use of the Services. If we learn of a security system’s breach, we may attempt to notify you electronically by sending a notice through the Services or by sending an e-mail to you.</Body2>
                    <H5 className="mb-1">Third Party Websites/Applications</H5>
                    <Body2 className="mb-3">The Services may contain links to other websites/applications and other websites/applications may reference or link to our Services. These third-party services are not controlled by us. We encourage our users to read the privacy policies of each website and application with which they interact. We do not endorse, screen or approve, and are not responsible for the privacy practices or content of such other websites or applications. Visiting these other websites or applications is at your own risk.</Body2>
                    <H5 className="mb-1">Children’s Information</H5>
                    <Body2 className="mb-3">The Services are not directed to children under 13 (or other age as required by local law), and we do not knowingly collect personal information from children. If you learn that your child has provided us with personal information without your consent, you may contact us as set forth below. If we learn that we have collected any child’s personal information in violation of applicable law, we will promptly take steps to delete such information.</Body2>
                    <H5 className="mb-1">Changes to Our Privacy Policy</H5>
                    <Body2 className="mb-3">We may revise this Privacy Policy from time to time in our sole discretion. If there are any material changes to this Privacy Policy, we will post a notice of the update on our Site, or as otherwise required by applicable law. You understand and agree that you will be deemed to have accepted the updated Privacy Policy if you continue to use the Services after the new Privacy Policy takes effect.</Body2>
                    <H5 className="mb-1">Contact Us</H5>
                    <Body2 className="mb-3">If you have any questions about our privacy practices or this Privacy Policy, please contact us at <a href="mailto:info@patientaccess.org">info@patientaccess.org</a></Body2>
                </>
            </DialogContent>
            <DialogActions>
                <div>
                    <ButtonDefault onClick={onClose} eventName="tos_agree">
                        Done
                    </ButtonDefault>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default PrivacyPolicy;
