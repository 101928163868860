import { HelpOutline } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

type Props = {
    label: string;
    content: string;
};

const HelperComponent: React.FC<Props> = ({ label, content }) => {
    return (
        <div className="flex items-center justify-center">
            <Tooltip title={content} placement="top" arrow>
                <div className="flex items-center gap-2 cursor-help">
                    <HelpOutline className="text-blue" />
                    <span className="text-blue hidden sm:block">{label}</span>
                </div>
            </Tooltip>
        </div>
    );
};

export default HelperComponent;
