import React, { useState } from 'react';
import {Tabs, Tab, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import cx from "classnames";

import LookingFor from "./lookingFor";
import Results from "./results";
import Insurance from "./insurance";
import Prescribed from "./prescribed";
import DiagnosedWith from "./diagnosedWith";
import ButtonDefault from "../_common/button/default";
import ButtonSecondary from "../_common/button/secondary";
import CustomStepper from '../_common/customStepper';
import HelperComponent from "../_common/helperComponent";
import Body1 from "../_common/text/body1";
import H1 from "../_common/text/h1";
import useTrackEvent from "../../hooks/useTrackEvent";

const STEPS = [
    { label: "Help!", Component: LookingFor },
    {
        label: "Diagnosis",
        Component: DiagnosedWith,
        HelperComponent: () => (
            <HelperComponent
                label="Don't see your condition?"
                content="If you don't see your condition, choose Rare Disease or Other from the bottom of the list."
            />
        ),
    },
    {
        label: "My Rx",
        Component: Prescribed,
        HelperComponent: () => (
            <HelperComponent
                label="Don't see your medication?"
                content="If you don't see your medication, press Next to continue. You will be able to search available resources at the end."
            />
        ),
    },
    { label: "Insurance", Component: Insurance },
    { label: "Results", Component: Results },
];

interface Props {
    onExit: () => void;
}

const ResourceFinder: React.FC<Props> = ({ onExit }) => {
    const [showExit, setShowExit] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const ActiveComponent = STEPS[activeStep].Component;
    const ActiveHelperComponent = STEPS[activeStep].HelperComponent;
    const trackEvent = useTrackEvent();

    const onBackClick = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    };

    const onNextClick = () => {
        if (activeStep < STEPS.length - 1) {
            setActiveStep(activeStep + 1);
        }
    };

    const onExitConfirmClick = () => {
        setShowExit(false);
        setActiveStep(0);
        onExit();
    };

    const onExitClick = () => {
        trackEvent("exit_click");
        setShowExit(true);
    }

    return (
        <div className="h-full overflow-hidden relative">
            <div className="absolute inset-0">
                <div className="flex flex-col sm:items-center h-full overflow-hidden">
                    <div className="hidden sm:block">
                        <div className="sm:min-w-[600px] pt-6 relative">
                            <CustomStepper activeStep={activeStep} steps={STEPS} />
                        </div>
                        <div className="absolute right-2 top-[6px] pointer-events-auto">
                            <IconButton onClick={onExitClick}>
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                    <div className="sm:hidden pointer-events-none pl-2 pr-10 border-0 border-b border-solid border-gray-5">
                        <Tabs
                            value={activeStep}
                            onChange={(event: React.SyntheticEvent, newValue: number) => setActiveStep(newValue)}
                            variant="scrollable"
                            scrollButtons={false}
                            allowScrollButtonsMobile
                            aria-label="scrollable force tabs example">
                            {STEPS.map((step, index) => (
                                <Tab key={`mobile-step-${step.label}`} label={(
                                    <div className={cx("border-2 border-solid rounded-full px-2 py-1", {
                                        "text-purple": index <= activeStep,
                                        "border-purple": index === activeStep,
                                        "border-transparent": index !== activeStep,
                                    })}>{step.label}</div>
                                )} />
                            ))}
                        </Tabs>
                        <div className="absolute right-[1px] top-[6px] pointer-events-auto bg-white">
                            <IconButton onClick={onExitClick}>
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                    <div className="flex-1 p-4 sm:p-6 overflow-y-scroll w-full text-center">
                        <ActiveComponent />
                    </div>
                    <div className="flex w-full p-4 items-center">
                        <div className={cx({
                            invisible: activeStep === 0,
                        })}>
                            <ButtonSecondary onClick={onBackClick} eventName="back_click">
                                Back
                            </ButtonSecondary>
                        </div>
                        <div className="flex-1 text-center">{ActiveHelperComponent && <ActiveHelperComponent />}</div>
                        <div className={cx("transition-none", {
                            invisible: activeStep === STEPS.length - 1,
                        })}>
                            <ButtonDefault onClick={onNextClick} eventName="next_click" eventParams={{activeStep}}>
                                Next
                            </ButtonDefault>
                        </div>
                    </div>
                </div>
                <div className={cx("absolute inset-0 bg-white flex flex-col justify-center items-center transition-all duration-300 px-4 text-center", {
                    "opacity-0 pointer-events-none": !showExit,
                    "opacity-100 pointer-events-auto": showExit
                })}>
                    <H1>
                        Exit resource finder?
                    </H1>
                    <Body1 className="my-4">
                        Are you sure you want to exit? Changes will not be saved.
                    </Body1>
                    <div className="w-[200px]">
                        <div className="mb-2">
                            <ButtonDefault className="w-full" onClick={() => setShowExit(false)} eventName="exit_cancel_click">
                                No, go back
                            </ButtonDefault>
                        </div>
                        <div>
                            <ButtonSecondary className="w-full text-center" onClick={onExitConfirmClick} eventName="exit_confirm_click">
                                Yes, exit
                            </ButtonSecondary>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResourceFinder;
